import React from "react";
import { Table } from "antd";

import Console from "../components/console";
import Seo from "../components/seo";

const dataSource = [
    {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
    },
    {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
    },
];

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    }
];

class Messages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: null,
            loading: true,
            data: null
        };
    }

    componentDidMount() {
        
    }

    render() {
        return <Console menu="messages" help={["transaction_validation_notification"]}>
            <Seo title="Messages" />
            <h1>Boite de réception</h1>
            <Table dataSource={dataSource} columns={columns} />
        </Console>;
    }
}


export default Messages;
